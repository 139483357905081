import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Navbar extends Component {

  render() {
    return (
      <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap shadow mb-5">

<div class="container">

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='50'
                height='50'
                src={makeBlockie(this.props.account, 50).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='50'
                height='50'
                src={`data:image/png;base64,${new Identicon(this.props.account, 50).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }



<small className="text-white">Account: <span id="account">{this.props.account}</span></small>

            <a class="navbar-brand" href="index.html">Evidencer</a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
           <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="wl-step-by-step.html">Step by Step - Develop a Claim</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="wl-service-providers.html">Service Providers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="wl-encrypt-and-submit.html">Encrypt & Submit</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#more-info" id="navbarDropdownPortfolio" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
WorldLaw Protocols
            </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
            <a class="dropdown-item" href="wl-swarmers-id.html">Establishing a WorldLaw Identity</a>
            <a class="dropdown-item" href="wl-tidbits.html">Encrypting and Uploading Content</a>
            <a class="dropdown-item" href="wl-gumshoe.html">Passing Secure Decode Keys</a>
            <a class="dropdown-item" href="wl-proofer.html">Locating Reassembling & Decoding</a>
            <a class="dropdown-item" href="wl-evidencer-quick-access.html">Evidencer Quick Access</a>
      </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#more-info" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Choose Service
            </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
            <a class="dropdown-item" href="wl-tidbits-encrypt-and-upload.html">Tidbits Encrypt & Upload</a>
            <a class="dropdown-item" href="wl-myevidence-pem.html">MyEvidence Personal Evidence Manager</a>
            <a class="dropdown-item" href="wl-evidencer-em.html">Evidencer Evidence Management</a>
      </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#more-info" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
More Info - How To
            </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
            <a class="dropdown-item" href="wl-typical-use-case.html">Typical Use Case</a>
            <a class="dropdown-item" href="wl-develop-claim.html">Step by Step - Develop a Claim</a>
            <a class="dropdown-item" href="wl-more-info-faq.html">More Info & FAQ</a>
            <a class="dropdown-item" href="wl-site-map.html">Site Map - Home</a>
            <a class="dropdown-item" href="wl-transaction-fees.html">Transaction Fees</a>
      </div>
          </li>
          </ul>
      </div>
      </div>
  </nav>
);
}
}

export default Navbar;
