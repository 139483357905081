import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class GutTop extends Component {

  render() {
    return (

<div class="container">

  <section class="page-section" id="services">
      <div class="row">
      <div class="col-lg-12 text-center">
        <h2 class="section-heading text-black my-4"><strong>Claim Management Tools</strong></h2>
        <h3 class="section-subheading text-black">The WorldLaw protocol located at Evidencer.eth contains tools that interact with Swarm, IPFS and Ethereum public nodes. Content files like images and documents are located on Swarm and IPFS while the locator files and decryption instructions are located on the Ethereum nodes hosting the blockchain. </h3>
      </div>
      </div>
      <div class="row text-center">
      <div class="col-md-4">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-lock-open fa-stack-1x fa-inverse "></i>
          </span>
        <h4 class="service-heading">Un-encrypt with Decode Key</h4>
            <p class="text-muted">When a WorldLaw claim is executed it creates an immutable transaction that can be used as proof of the existence of a electronic file. ...<small>.</small></p>
      </div>
      <div class="col-md-4">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-balance-scale fa-stack-1x fa-inverse "></i>
          </span>
        <h4 class="service-heading">Evidencer Files Provide Court Clean Records<small>.</small></h4>
            <p class="text-muted">All public court systems are stressed to receive and manage electronic evidence. ....<small>.</small></p>
      </div>
      <div class="col-md-4">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-link fa-stack-1x fa-inverse "></i>
          </span>
        <h4 class="service-heading">Secured on Blockchain</h4>
            <p class="text-muted">The claim transaction is secured and available 24/7/365 presumably forever. Courts can rely on the public blockchain as proof of the existence of a claim file without every having to view the contents until the rules of evidence require it. This keeps confidential files secure comporting with ethical rules of confidentuial and client attonrney priviliges.<small>.</small></p>
      </div>
      
      </div>
  </section>



  <section class="call-to-action text-white text-center mb-5"  id="executetx">
    <div class="container">
    <div class="row">
    <div class="col-xl-9 mx-auto">
    <div id="magic"></div>
      <a href="https://etherscan.io" target="_blank" rel="noopener noreferrer" class="h2 mb-4 text-white">Verify public blockchain transaction &nbsp;<i class="fab fa-ethereum"></i></a>



    </div>

    <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
    </div>
    </div>


    </div>

</section>


        <h1 class="my-4">Evidencer Use</h1>

      <div class="row">
      <div class="col-lg-4 mb-4">
      <div class="card h-100">
        <h4 class="card-header">Locate a Evidence Record</h4>
      <div class="card-body">
            <p class="card-text">This is simply a transaction id known as a hash or an address like <strong>SamSmithEvid2020.evidencer.eth</strong>. The WorldLaw system offers human readable addresses for all transaction executed through the system.<small>.</small></p>
      </div>
      <div class="card-footer">
            <a href="#more-info" class="btn btn-primary">Locate Claim Address</a>
      </div>
      </div>
      </div>
      <div class="col-lg-4 mb-4">
      <div class="card h-100">
        <h4 class="card-header">Pass it through Evidencer.eth</h4>
      <div class="card-body">
            <p class="card-text">The Evidencer smart-contract protocol reaches out to ach node system and locates all of the pieces if the encrypted file and reassembles it back to the original encrypted form. The file can be verified by its’s existence but cannot be viewed without the decode key that was set when the file was originally encrypted.</p>
      </div>
      <div class="card-footer">
            <a href="#more-info" class="btn btn-primary">Execute Evidencer Claim</a>
      </div>
      </div>
      </div>
      <div class="col-lg-4 mb-4">
      <div class="card h-100">
        <h4 class="card-header">Decode the Encrypted Files</h4>
      <div class="card-body">
            <p class="card-text">The claimant is the master of the evidence and claim file. Based on the claimant’s strategy along with the assistance of a Blufin (lawyer/agent) the file can be re-encrypted for down-the-line viewing by opposing parties and triers of fact, like juries.<small>.</small></p>
      </div>
      <div class="card-footer">
            <a href="#more-info" class="btn btn-primary">Verify by Proof of Existence</a>
      </div>

      </div>
      </div>
      </div>


</div>

);
}
}

export default GutTop;
