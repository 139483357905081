import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class GutBot extends Component {

  render() {
    return (

<div class="container">




<section class="call-to-action text-white text-center mb-5"  id="executetx">
   

            <a href="https://etherscan.io" target="_blank" rel="noopener noreferrer" class="btn btn-lg btn-primary">Verify by Proof of Existence</a>



<div class="col-md-10 col-lg-8 col-xl-7 mt-2 mb-2 mx-auto">

</div>

<div class="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black">Current User: <span id="account">{this.props.account}</span>

</div>
</div>

</section>

</div>


);
}
}

export default GutBot;
