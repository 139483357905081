import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut2 extends Component {

  render() {
    return (

<div class="container">

<section class="call-to-action text-white text-center mb-5">
<div class="container">

<h5 class="card-title">Evidencer does not require a username or password. <br></br>This site collects no information from you. <br></br>It simply allows you to interact with a public system of node computers <br></br> and blockchains that store and parse data.</h5>
</div>

<a class="btn btn-lg btn-secondary btn-block text-center" href="http://onelaw.us" target="_blank" rel="noopener noreferrer">Try the Tidbits image uploader.</a>

</section>



<div class="row">
<h2 class="col-sm-12">Popular Web3vm Applications</h2>
<div class="col-lg-4 col-md-6 col-sm-12 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/users-200w1.png" alt="WL-bluefin"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Users<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Users are people that have to keep track of valuable records but don't have the tools or resources for an easy implementation. Web3vm provides simple time saving management tools that interoperate with public blockchains to insure data inegrity.</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Users</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/scale0-200w.png" alt="WL-gumshoe"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">WorldLaw<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">WorldLaw is a claim manager application. Resolving claims in the U.S. is largely a function of logistics.  There are arbitrations, mediations, and brick-and-mortar courthouses and each decison center requires legally admissible evidence.  This system is solely focused on that purpose. This is not a court or a lawyers office. It is not used to make legal arguments or to provide legal advice. It is a tool to be more efficient in operating in an existng legal frameworks.  Lawsuits and claims do not need to be ineffieient anymore. Public node and blockchain systems change eveything by providing efficiencies.  </p>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to WorldLaw</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/comet-200w.png" alt="WL-tidbits "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">MyEvidence<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">MyEvidence is a portal that ...... are electronically stored content files with integrated testimony. These content files include images, audio, video, documents and any other form or electronically recorded file.</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to MyEvidence</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-md-6 col-sm-12 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/gumballs-200w.png" alt="WL-proofer "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Tidbits<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Tidbits are electronically stored content files with integrated testimony. These content files include images, audio, video, documents and any other form or electronically recorded file. Once the files are encrypted and loaded to the public node systems they are safe from prying eyes yet still create a imutable public record that can be acalled at anytime.</p>
<br></br>
<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Tidbits</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>





<div class="col-lg-4 col-md-6 col-sm-12 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/evid-bluefin.png" alt="WL-lawdocs "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Bluefin<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Bluefin is a set of effiency tools that assist lawyer and agents in managing claims and electronic evidence on the public nodes and blockchains. The WorldLaw system provides access to simple time saving management tools that interoperate with public blockchains to insure data inegrity thus saving lawyers, courts and client incalculable hours in chain-of-custody and authentication.</p>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Bluefin</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>

<div class="col-lg-4 col-md-6 col-sm-12 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/scopegears-200w.png" alt="WL-judiciary"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Gumshoe<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">A Gumshoe is a private investigator. Web3vm tools provide investigators simple ways to encrypt and load content files into a public systems of nodes and blockchains for easy and reliable chain-of-custody.A Gumshoe is a private investigator. Web3vm tools provide investigators simple ways to encrypt and load content files into a public systems of nodes and blockchains for easy and reliable chain-of-custody.</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Gumshoe</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>



</div>


</div>
);
}
}

export default Gut2;
