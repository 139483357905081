import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut4 extends Component {

  render() {
    return (

<div class="container">


<h2><hr></hr>More Web3vm Applications</h2>

<div class="row">
<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/evid-lawdocs.png" alt="WL-lawdocs"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">LawDocs<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">LawDocs is a WorldLaw protocol tha offers tools to claimant and their legal representatives during the adjudication process. LawDocs is a WorldLaw protocol tha offers tools to claimant and their legal representatives during the adjudication process.</p>
<br></br>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to LawDocs</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/gears0-200w.png" alt="WL-gumshoe"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Codes<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Codes are are a combination codified laws, directives, and secondary resources used to prove claims.  Web3vm tools provide investigators simple ways to encrypt and load content files into a public systems of nodes and blockchains for easy and reliable chain-of-custody.</p>
<br></br>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Codes</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/compass-200w.png" alt="WL-tidbits "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Claim<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">A claim is a .......  are electronically stored content files with integrated testimony. These content files include images, audio, video, documents and any other form or electronically recorded file. are electronically stored content files with integrated testimony. These content files include images, audio, video, documents and any other form or electronically recorded file.</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Claim</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/proxy1-200w.png" alt="WL-proofer "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Proxy1<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">A proxy is the authority to represent someone else, especially in voting. ........   is a document and transaction verifier. It is a manual process that is executed by a identifiable human being who attests to the existence of the on-chain/node transactions.</p>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Proxy1</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>





<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/judge-200w.png" alt="WL-lawdocs "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Judiciary<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">The Judiciary protocol execute automation of locating, reassembling and decoding claim and electronic evidence files. The Judiciary protocol execute automation of locating, ..... The Judiciary protocol execute automation of locating, reassembling and decoding claim and electronic evidence files. The Judiciary protocol execute automation of locating, ....</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Judiciary</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>

<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/voteable-200w.png" alt="WL-judiciary"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Voteable<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">The Voteable protocol is used to tally straight non-proxy votes.  It may be used to pool juries, run surveys, or hold auctions. The process of voting is simple however the rules of who can vote, and when voting occur are a matter of logistics.  See Proxy1 for proxy voting alternatives. </p>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Voteable</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>


<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/videocamera-200w.png" alt="WL-bluefin"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Videos<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Videos are important evidence. Use Videos.eth to memorialize a video clip where you can also attach a testimonial statement that will help in autenticating the video for court use. Your testimony can be free-form as long as it clearly states a relevent point that survices a hearsay challenge. Seek legal advice if you need a clarification of crafting proper testimony.</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Videos</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/microphone-200w.png" alt="WL-gumshoe"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">AudioFiles<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Audio recordings are important evidence. Use AudioFiles.eth to memorialize a audio file where you can also attach a testimonial statement that will help in autenticating the audio for court use. Seek legal advice if you need a clarification of crafting proper testimony.</p>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to AudioFiles</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/evid-tidbits.png" alt="WL-tidbits "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">MyDocs<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Electronic document comprise are vast majority or modern evidence and is very important evidence in proving a claim. Use MyDocs.eth to memorialize a audio file where you can also attach a testimonial statement that will help in autenticating the audio for court use. Seek legal advice if you need a clarification of crafting proper testimony.</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Tidbits</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>
<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/lighthouse-200w.png" alt="WL-proofer "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Prepay<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">Prepay is an escrow-type of service that allows a trusted third party to temporaily take custoody of a fee or payment that may only be released to another party based on a specific set ogf instructions. Modern escrow services do not require a license agents unless the transaction reaches a certain threshold usually lying in real-estae and certain banking transactions. DO NOT USE THIS SERVICE for those type of trasactions, only use a bonded escrow agent for those types of transactions.</p>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Prepay</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>



<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/cartoonglobe-200w.png" alt="WL-lawdocs "></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Promised<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">A Promise is a declaration that one will do or refrain from doing something specified. Legal contracts are based on promises with some type of value attached. Promised.eth is a simple contractual protocol that allows parties to craft an agreement through defined mutually agreeable terms and memorialize them on the public blockchain.</p>
<br></br>
<br></br>


<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to Promised</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>

<div class="col-lg-4 col-sm-6 portfolio-item">
<div class="card h-100">
<a href="http://onelaw.us"><img class="card-img-top" src="../img/magichat-200w.png" alt="WL-judiciary"></img></a>
<div class="card-body">
<h4 class="card-title">
<a href="http://onelaw.us">Airdrop<span class="text-muted">.eth</span></a>
</h4>
<p class="card-text">AirDrop.eth is used to facilitate growth of a userbase of users. Participants can share ERC-20 and ERC-777 tokens through a simple interface by using any Web3 ready signing account. This application is primarly used to on-board new system users by pre-funding short-term use accounts that help new users navigate the system without risking large sums of money or accidentally compromising private information</p>
<br></br>

<div class="col-md-12 col-lg-12 col-xl-12 mx-auto">
<form>
<div class="form-row">
<div class="col-12 col-md-12 mb-2 mb-md-0">
</div>
<div class="col-12 col-md-12">
<a href="https://web3evm.com" class="btn btn-block btn-md btn-primary">Go to AirDrop</a>
</div>
</div>
</form>
</div>

</div>
</div>
</div>


</div>

</div>

);
}
}

export default Gut4;
