import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Footer extends Component {

  render() {
    return (



<footer class="footer text-center bg-light">
      <div class="container">
      <div class="row">
      <div class="col-lg-12 h-100 text-center text-lg-center my-auto mb-5">  
           <ul class="list-inline mt-2 mb-2">
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">WorldLaw</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Users</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Bluefin</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Gumshoe</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">LawDocs</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Tidbits</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Judiciary</a>
          </li>
          </ul>
          
           <ul class="list-inline mb-0">
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmltwitter">
            <i class="fab fa-twitter-square fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmlgithub">
            <i class="fab fa-github fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.htmlbitcoin">
            <i class="fab fa-bitcoin fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmlethereum">
            <i class="fab fa-ethereum fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmlgaspump">
            <i class="fas fa-gas-pump fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.htmldice">
            <i class="fas fa-dice-d6 fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmlupload">
            <i class="fas fa-upload fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmldownload">
            <i class="fas fa-file-download fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.htmlagent">
            <i class="fas fa-user-tie fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmlshield">
            <i class="fas fa-shield-alt fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item mr-3">
            <a href="wl-more-info-faq.htmllandmark">
            <i class="fas fa-landmark fa-2x fa-fw "></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="wl-more-info-faq.htmlsliders">
            <i class="fas fa-sliders-h fa-2x fa-fw "></i>
            </a>
          </li>
          </ul>
          </div>
      </div>


      <div class="d-flex justify-content-center mt-5">

      { this.props.account
  ? <img
    className='ml-1 rounded-5'
    width='24'
    height='24'
    src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
  : <span></span>
}

{ this.props.account
  ? <img
    className='ml-1 rounded-5 mr-2'
    width='24'
    height='24'
    src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
  : <span></span>
}
<small className="text-black text-center">Account: <span id="account">{this.props.account}</span></small>
      </div>

      <div class="container">
            <p class="m-0 text-center text-black mb-5">Copyright &copy; WorldLaw 2020 - WorldLaw.eth</p>
      </div>

      </div>



      </footer>

);
}
}

export default Footer;
