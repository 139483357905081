import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut3 extends Component {

  render() {
    return (

<div class="container mt-5">

<div class="row">
<div class="col-lg-6 ">
<h2>Evidencer manages electronic evidence on public nodes and blockchains. Federal Rules of Evidence requires authenticate by proper chain-of-custody of records prior to being admitted in court. </h2>
<p class="textdesc"><emblue>This site does not collect your data, it provides a vehicle to interact with the public nodes and blockchains.</emblue></p>
<ul>
<li class="textdesc"><strong>Evidencer encrypts data locally – Your unencrypted data never leaves your device.</strong></li>
<li class="textdesc">Mnemonic triggered decode keys</li>
<li class="textdesc">Proxy re-encryption for down-the-line viewing</li>
<li class="textdesc">Immutability on Ethereum public blockchain</li>
<li class="textdesc">Evidential content files encrypted and stored on public nodes</li>
</ul>

<p class="textdesc"><strong><emred>NOTE ON ENCRYPTION</emred></strong>: All files should be encrypted before copied to the node system. Encrypted files are saved blocks in a distributed system with no single point of failure. This assures the files are available 24/7/365 in an encrypted state until the record is found, reassembled, and then decoded. <emred>Unless the user wants the files to be publicly viewed, only the encrypted files created from the original should be placed on the node and blockchain system. </emred>Only the original party who creates the evidence files will know the decode key unless it is shared. You can use the system now.</p>
</div>
<div class="col-lg-6">
<img class="img-fluid rounded" src="../img/700x460-ltgr-logos-01-wl.png" alt="WL-ltgr-logos-01"></img>
</div>
</div>

</div>

);
}
}

export default Gut3;
