import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut1 extends Component {

  render() {
    return (


<section class="call-to-action text-white text-center mb-5">
      <div class="container">
      <div class="row">
      <div class="col-xl-9 mx-auto">
        <h4 class="m2-4">This is the current user signing identity:</h4>
      </div>

      </div>
      </div>

  <div class="d-flex justify-content-center mt-2">

{ this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='40'
                height='40'
                src={makeBlockie(this.props.account, 40).toString()}alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5 mr-4'
                width='40'
                height='40'
                src={`data:image/png;base64,${new Identicon(this.props.account, 40).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<h4 className="text-black"><span id="account">{this.props.account}</span></h4>

</div>


</section>

);
}
}

export default Gut1;
